import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import './pageStyles/history.scss'

const HistoryPage = () => {
  return (
    <Layout>
      <Seo title="History of Single Patient Use Anesthesia Breathing Circuits in USA" />
      <div id="page-container" style={{ overflowY: "hidden" }}>
        <div id="hist-boc et-boc" className="hist-boc et-boc">
          <div id="hist-main et-main-area">
            <div id="main-content">
              <article id="post-445" className="post-445 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="hist-l et-l hist-l et-l--post">
                    <div className="hist_builder_inner_content et_builder_inner_content hist_pb_gutters3 et_pb_gutters3">
                      <div className="hist_pb_section et_pb_section hist_pb_section_7 et_pb_section_7 hist_pb_with_background et_pb_with_background hist_section_regular et_section_regular section_has_divider hist_pb_bottom_divider et_pb_bottom_divider hist_pb_top_divider et_pb_top_divider optml-bg-lazyloaded hist_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: "90px" }}>
                        <div className="hist_pb_row et_pb_row hist_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                          <div className="hist_pb_column et_pb_column hist_pb_column_2_3 et_pb_column_2_3 hist_pb_column_14 et_pb_column_14  hist_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_35 et_pb_text_35 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><p>History of Single Patient Use Anesthesia Breathing Circuits in the USA</p></div>
                            </div>
                          </div>
                          <div className="hist_pb_column et_pb_column hist_pb_column_1_3 et_pb_column_1_3 hist_pb_column_15 et_pb_column_15  hist_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough hist-last et-last-child">
                            <div className="hist_pb_module et_pb_module hist_pb_image et_pb_image hist_pb_image_3 et_pb_image_3 optml-bg-lazyloaded">
                              <span className="hist_pb_image_wrap et_pb_image_wrap ">
                                <img decoding="async" src="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133113/Arc_Medical/circuitguard-logo.png"
                                  alt="circuitguard-logo" title="circuitguard-logo"
                                  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1000px, 100vw"
                                  data-pagespeed-url-hash="2581500090" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="hist_pb_bottom_inside_divider et_pb_bottom_inside_divider"></div>
                      </div>
                      <div className="hist_pb_section et_pb_section hist_pb_section_8 et_pb_section_8 hist_section_regular et_section_regular">
                        <div className="hist_pb_row et_pb_row hist_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                          <div className="hist_pb_column et_pb_column hist_pb_column_4_4 et_pb_column_4_4 hist_pb_column_16 et_pb_column_16  hist_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough hist-last et-last-child">
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_36 et_pb_text_36 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <h2>When learning about circuitGuard&reg;, a little history as to how the USA came to use Single Patient Use Anesthesia Breathing Circuits (SPU ABC) is helpful.</h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_37 et_pb_text_37 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner">
                                <div className="page" title="Page 1">
                                  <div className="section">
                                    <div className="layoutArea">
                                      <div className="column">
                                        <div className="page" title="Page 1">
                                          <div className="section">
                                            <div className="layoutArea">
                                              <div className="column">
                                                <h1>History Prior to SPU ABCs Placed into Service</h1>
                                                <div className="page" title="Page 1">
                                                  <div className="section">
                                                    <div className="layoutArea">
                                                      <div className="column">
                                                        <p>More than 30 years ago, black rubber tubing held in place by a “Christmas tree” (metal plate with flanges) was the common connection between the patient and the anesthesia machine. Typical uses were one circuit for a day (or patient) and simply wash the circuit and hang to dry for use the next day.</p>
                                                        <p>When patient charge items started, the circuit manufacturers sold circuits labeled “disposable”. Anesthesia providers, however, did not change their practice – many continued to use the circuit as before. The reason the USA uses circuits labeled “Single Patient Use” was decided by 6 men in a conference room in New Jersey. There is no study showing disposing a circuit after one use reduces the risk of cross-contamination. This was purely a marketing decision. Other companies were either ahead of them or followed suit.</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_38 et_pb_text_38 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Patient Charge Items / Diagnosis Related Groups (DRGs)</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>After SPU ABCs came into use, hospitals charged patients for most items consumed by a patient during their stay. DRGs changed that process. However, anesthesia providers liked the light weight and ease of use of the SPU ABCs. As a result, hospitals were persuaded the SPU ABCs were necessary as an infection control item.</p>
                                                      <p>There has never been a study that shows SPU ABCs reduce or prevent cross-contamination. Common sense says if you throw away the connecting tubing between the patient and the machine the patient will be protected (but not the machine or anesthesia provider), but no study proves this.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_39 et_pb_text_39 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Decision Process Outside the USA</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>Outside the USA, the issue of patient charge items never surfaced. As a result, the decision-making process was different. Bacterial / viral filters were available and use of a filter at the wye became common practice.</p>
                                                      <p>Again, common sense says if you protect the circuit from contamination, then the patient, circuit, machine, and anesthesia provider are protected. For cross-contamination to occur, an organism would have to travel through two filters – one to get into the circuit and then through a second (new) filter on the next patient. The odds of an organism passing through two filters are enormous. There are many studies documenting the effectiveness of using filters in this manner.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_40 et_pb_text_40 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Legal to Use</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p><span style={{ fontSize: "18px" }}>It is legal to use a circuit labeled SPU for MPU if documentation (Policy and Procedure) is in place.</span></p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_41 et_pb_text_41 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Pass Accreditation</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>Facilities using SPU labeled ABCs have passed inspection by all the accreditation agencies – JCAHO, CMS and AAAHC.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_42 et_pb_text_42 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Sustainability Initiatives</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>Reduction in the use of plastics is preferable to recycling today. MPU of an ABC can reduce the number of circuits sent to landfill by up to 60% depending on the change frequency.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_43 et_pb_text_43 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Increased Room Turnover Efficiency</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>Replacing the filter and mask is all that is necessary to turn the room. Disposing and replacing the anesthesia circuit takes more time.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_44 et_pb_text_44 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>Protection of Patient and Care Provider</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>By filtering next to the patient, the anesthesia circuit and ancillary equipment (CO2 lines, absorber, internal workings of the machine) are protected from the patient. A new filter protects the next patient from the anesthesia equipment.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_module et_pb_module hist_pb_text et_pb_text hist_pb_text_45 et_pb_text_45 hist_pb_text_align_left et_pb_text_align_left hist_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="hist_pb_text_inner et_pb_text_inner"><div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <div className="page" title="Page 1">
                                        <div className="section">
                                          <div className="layoutArea">
                                            <div className="column">
                                              <h1>What are the results?</h1>
                                              <div className="page" title="Page 1">
                                                <div className="section">
                                                  <div className="layoutArea">
                                                    <div className="column">
                                                      <p>In more than 60,000,000 uses of filter devices in N. America, Europe and Asia, there has never been a cross-contamination reported to any regulatory agency. It is also true there has never been a cross-contamination reported to any regulatory agency in any country where a SPU ABC has been used and discarded after one patient.</p>
                                                      <p>The results are the same.</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div className="hist_pb_button_module_wrapper et_pb_button_module_wrapper hist_pb_button_3_wrapper et_pb_button_3_wrapper hist_pb_button_alignment_center et_pb_button_alignment_center hist_pb_module et_pb_module optml-bg-lazyloaded">
                              <a className="hist_pb_button et_pb_button hist_pb_button_3 et_pb_button_3 hist_pb_bg_layout_light et_pb_bg_layout_light" href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629128953/Arc_Medical/History-of-Single-Patient-Use-Anesthesia-Breathing-Circuits-in-USA-2021.pdf" target="_blank" rel="noreferrer">Download History of SPU ABCs</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>)
}

export default HistoryPage
